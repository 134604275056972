<template>
	<div>
		<hero title="Nationally Recognised Training" subtitle="Nationally recognised online learning to help you launch or advance your career." class="mb-6"></hero>
		<b-container>
			<b-row class="mb-5 mb-lg-3">
				<b-col lg="7">
					<div class="op-heading mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip.</div>
				</b-col>
				<b-col lg="4" offset-lg="1" class="font-size-xs">
					<div class="bg-light p-3 box-shadow-2">
						<img src="/assets/img/NRT_title.png" alt="" class="mb-2">
						<div class="d-flex flex-column flex-sm-row flex-lg-column">
							<p class="mb-1">WiseTech Academy is a <strong>Registered Training Organisation</strong>
							and an <strong>Australian Government approved</strong> education provider.
							Our RTO Code is <strong>45574</strong></p>
							<p class="text-right m-0 flex-shrink-0">
								<img src="/assets/img/NRT.svg" alt="" >
							</p>
						</div>
					</div>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<heading small>Explore our courses</heading>
				</b-col>
			</b-row>
		</b-container>
		<div class="bg-light py-5">
			<b-container>
				<b-row>
					<training-course :item="$store.state.courses[0]"/>
					<training-course :item="$store.state.courses[1]"/>
				</b-row>
			</b-container>
		</div>
		<div class="bg-dark mb-6">
			<b-container>
				<b-row class="justify-content-start">
					<b-col class="text-white py-5" md="6">
						<h1>Get our student handbook</h1>
						<h3 class="text-grey-1 font-weight-normal">Download our handbook for more information and our RTO policies and procedures.</h3>
						<b-button href="/assets/WiseTech_Handbook_v7_Current_Scope.pdf" target="_blank">Get Handbook</b-button>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<contact-form title="Not sure which course is right for you?">For more information, please explore our course catalogue. If you'd like to be informed of new course releases, please leave us your contact information.</contact-form>
	</div>
</template>

<script>
import { TrainingCourse } from "@/components/ui";
export default {
	name: 'Training',
	components: {
		'training-course': TrainingCourse
	}
}
</script>